export default class StopWatch {
  timestamp: number;

  constructor() {
    this.timestamp = Date.now();
  }

  getMS() {
    return Date.now() - this.timestamp;
  }

  start() {
    this.timestamp = Date.now();
  }
}
