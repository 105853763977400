<template>
  <div class="speed-block" :class="theme">
    <div class="title color-tertiary" v-html="titleTranslation" />

    <div class="inner">
      <div v-if="!isLoading && value" class="content">
        <IconSVG v-if="isForDownload" :svg="IconArrowDown" :class="colorClass" />
        <IconSVG v-else :svg="IconArrowUp" :class="colorClass" />
        <div>{{ value }} Mbit/s</div>
      </div>

      <div v-if="!isLoading && value" class="content">
        <IconSVG :svg="IconCrossCircled" class="color-error" />
      </div>

      <LoaderSimple v-if="isLoading" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LoaderSimple from './LoaderSimple.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCrossCircled from 'src/svg/cross-circled.svg';
import IconArrowDown from 'src/svg/arrow-down.svg';
import IconArrowUp from 'src/svg/arrow-up.svg';
import { SequoiaComponent } from 'src/mixins';

@Component({
  components: {
    LoaderSimple,
    IconSVG,
  },
})
export default class SpeedBlock extends SequoiaComponent {
  IconCrossCircled = IconCrossCircled;
  IconArrowDown = IconArrowDown;
  IconArrowUp = IconArrowUp;

  @Prop()
  isLoading!: boolean;

  @Prop()
  color?: string;

  @Prop()
  value?: string;

  @Prop()
  isForDownload!: boolean;

  get colorClass() {
    switch (this.color) {
      case 'red':
        return 'color-error';
      case 'yellow':
        return 'color-warning';
      default:
        return 'color-success';
    }
  }

  get titleTranslation() {
    return this.isForDownload
      ? this.getTranslation('diagnostics_download')
      : this.getTranslation('diagnostics_upload');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.speed-block {
  @include tablet-l {
    width: 50%;
  }
  @include mobile-and-tablet {
    margin-bottom: 8px;
  }

  .title {
    margin-bottom: 10px;

    @include mobile-and-tablet {
      margin-bottom: 4px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    min-width: 150px;
    min-height: 24px;
  }

  .content {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }

  .icon {
    margin-right: 10px;
  }
}
</style>
