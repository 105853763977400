<template>
  <section class="speed section flex-wrap">
    <div class="column">
      <h5>
        <TextWithHint
          :key="key"
          :hint="smotreshkaServerHint"
          :text="getTranslation('diagnostics_smotreshka')"
          border-style="dashed"
        />
      </h5>
      <div class="flex-wrap">
        <SpeedBlock
          :is-loading="isLoading"
          :is-for-download="true"
          :value="smotreshkaDownload || undefined"
          :color="smotreshkaDownloadColor"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SpeedBlock from '../SpeedBlock.vue';
import { SequoiaComponent } from 'src/mixins';
import TextWithHint from 'src/components/ui/TextWithHint.vue';

@Component({
  components: { TextWithHint, SpeedBlock },
})
export default class Speed extends SequoiaComponent {
  smotreshkaServerHint = '';
  internetServerHint = '';
  key = 0;

  @Prop()
  isLoading!: boolean;

  @Prop()
  smotreshkaServerName?: string;

  @Prop()
  smotreshkaDownload?: string | null;

  @Prop()
  smotreshkaDownloadColor?: string;

  @Prop()
  smotreshkaUpload?: string | null;

  @Watch('smotreshkaServerName')
  onSmotreshkaServerNameChange(value: string) {
    this.smotreshkaServerHint = `Скорость соединения до сервера ${value}`;
    this.key++;
  }

  @Watch('internetServerName')
  onInternetServerNameChange(value: string) {
    this.internetServerHint = `Скорость соединения до сервера ${value}`;
    this.key++;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.speed {
  .column {
    width: 50%;

    @include mobile-and-tablet {
      width: 100%;
      margin-bottom: 24px;
    }
  }
}

.flex-wrap {
  display: flex;

  @include mobile-and-tablet-s {
    flex-direction: column;
  }
}
</style>
