import * as arrayUtils from 'src/utils/array';
import StopWatch from 'src/utils/time/stop-watch';
import TestChecks from './test-checks';
import { bytesToBits, msToS } from 'src/utils/number';
import { TSpeedTest, TSpeedTestCheck } from 'src/api/app-info/types';
import { TSpeedTestEnhanced } from 'src/components/diagnostics/types';

export default class TestCore {
  requestStopWatch: StopWatch;

  payload: TSpeedTest;

  requestTime: number[][];

  successfulAttempts: number;

  failedAttempts: number;

  isChunksTest: boolean;

  constructor(payload: TSpeedTest) {
    this.requestStopWatch = new StopWatch();
    this.payload = payload;
    this.requestTime = [];
    this.successfulAttempts = 0;
    this.failedAttempts = 0;
    this.isChunksTest = !!payload.chunkSizeBytes && !!payload.numChunks;
  }

  run(): Promise<TSpeedTestEnhanced> {
    const { attempts } = this.payload;
    return arrayUtils
      .filledWithZeros(attempts)
      .reduce(
        (promise: Promise<string>, currentValue: undefined, currentIndex: number) =>
          promise
            .then(() => {
              return this.sendRequest(currentIndex + 1);
            })
            .then(() => {
              this.successfulAttempts += 1;
            })
            .catch(() => {
              this.failedAttempts += 1;
            }),
        Promise.resolve()
      )
      .then(() => ({ ...this.payload, stat: this.getStat(), failedChecks: this.doChecks() }));
  }

  doChecks() {
    const { title, humanReadableResource, checkList } = this.payload;
    const testChecks = new TestChecks(title, humanReadableResource);
    const failedChecks: Record<string, null | TSpeedTestCheck> = {};
    checkList.forEach((check) => {
      let failedCheck = null;
      switch (check.type) {
        case 'avg-ms':
          failedCheck = testChecks.checkAverageMs(check, this.requestTime);
          break;
        case 'max-ms':
          failedCheck = testChecks.checkMaxMs(check, this.requestTime);
          break;
        case 'min-ms':
          failedCheck = testChecks.checkMinMs(check, this.requestTime);
          break;
        case 'successful-attempts':
          failedCheck = testChecks.checkSuccessfulAttempts(check, this.successfulAttempts);
          break;
        case 'failed-attempts':
          failedCheck = testChecks.checkFailedAttempts(check, this.failedAttempts);
          break;
        case 'min-chunk-download-speed':
          failedCheck = testChecks.checkMinChunkDownloadSpeed(check, this.getChunkDownloadSpeed());
          break;
        case 'avg-chunk-download-speed':
          failedCheck = testChecks.checkAvgChunkDownloadSpeed(check, this.getChunkDownloadSpeed());
          break;
        case 'min-file-download-speed':
          failedCheck = testChecks.checkMinFileDownloadSpeed(check, this.getFileDownloadSpeed());
          break;
        case 'avg-file-download-speed':
          failedCheck = testChecks.checkAvgFileDownloadSpeed(check, this.getFileDownloadSpeed());
          break;
        default:
        // do nothing
      }
      if (failedCheck) {
        failedChecks[failedCheck.type] = failedCheck;
      }
    });
    return Object.values(failedChecks);
  }

  sendRequest(attempt: number) {
    if (!this.isChunksTest) {
      this.recordRequestStartTime();
    }
    return this.doSendRequest(attempt).finally(() => {
      if (!this.isChunksTest) {
        this.recordRequestEndTime(attempt);
      }
    });
  }

  doSendRequest(attempt: number) {
    const { timeLimitMs, chunkSizeBytes, numChunks } = this.payload;
    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.timeout = timeLimitMs;
      xhr.onerror = () => reject(new Error('Network error'));
      xhr.ontimeout = () => reject(new Error('Target host is not responding'));
      xhr.onload = () => resolve();
      xhr.open('GET', this.getUrl());
      if (chunkSizeBytes && numChunks) {
        this.recordRequestStartTime();
        xhr.setRequestHeader('Range', `bytes=0-${chunkSizeBytes * numChunks}`);
        let sequence = 1;
        let isSequenceStarted = true;
        xhr.onprogress = (e) => {
          if (isSequenceStarted && e.loaded >= e.total) {
            this.recordRequestEndTime(attempt);
          } else if (e.loaded > sequence * chunkSizeBytes) {
            this.recordRequestEndTime(attempt);
            isSequenceStarted = false;
            if (e.loaded < e.total) {
              isSequenceStarted = true;
              this.recordRequestStartTime();
              sequence++;
            }
          }
        };
      }
      xhr.send();
    });
  }

  getUrl() {
    return `${this.payload.resource}?_=${new Date().getTime()}`;
  }

  getChunkDownloadSpeed() {
    const chunkSizeBytes = this.payload.chunkSizeBytes || 0;
    const chunkSizeBits = bytesToBits(chunkSizeBytes);
    const result: number[] = [];
    this.requestTime.forEach((attempt) => {
      attempt.forEach((ms) => {
        // if some attempts were failed we assume that we can not measure the speed
        const seconds = msToS(ms);
        result.push(this.calcSpeed(chunkSizeBits, seconds));
      });
    });
    return result;
  }

  getFileDownloadSpeed() {
    const chunkSizeBytes = this.payload.chunkSizeBytes || 0;
    const chunkSizeBits = bytesToBits(chunkSizeBytes);
    const numChunks = this.payload.numChunks || 0;
    const fileSizeBits = chunkSizeBits * numChunks;
    return this.requestTime.map((attempt) => {
      // if some attempts were failed we assume that we can not measure the speed
      const ms = arrayUtils.sum(attempt) || 0;
      const seconds = msToS(ms);
      return this.calcSpeed(fileSizeBits, seconds);
    });
  }

  getStat() {
    let requestTime: number[] = [];
    this.requestTime.forEach((attempt) => {
      requestTime = requestTime.concat(attempt);
    });

    const stat = {
      resource: this.payload.resource,
      attempts: this.payload.attempts,
      successfulAttempts: this.successfulAttempts,
      failedAttempts: this.failedAttempts,
      requestTime: {
        max: arrayUtils.max(requestTime) || 0,
        min: arrayUtils.min(requestTime) || 0,
        avg: Math.round(arrayUtils.avg(requestTime) || 0),
      },
    };
    if (this.payload.type === 'xhr-speed') {
      return {
        ...stat,
        chunkDownloadSpeed: {
          max: arrayUtils.max(this.getChunkDownloadSpeed()) || 0,
          min: arrayUtils.min(this.getChunkDownloadSpeed()) || 0,
          avg: Math.round(arrayUtils.avg(this.getChunkDownloadSpeed()) || 0),
        },
        fileDownloadSpeed: {
          max: arrayUtils.max(this.getFileDownloadSpeed()) || 0,
          min: arrayUtils.min(this.getFileDownloadSpeed()) || 0,
          avg: Math.round(arrayUtils.avg(this.getFileDownloadSpeed()) || 0),
        },
      };
    }
    return stat;
  }

  recordRequestStartTime() {
    this.requestStopWatch.start();
  }

  recordRequestEndTime(attempt: number) {
    const i = attempt - 1;
    this.requestTime[i] = this.requestTime[i] || [];
    this.requestTime[i].push(this.requestStopWatch.getMS());
  }

  calcSpeed(bits: number, seconds: number) {
    return seconds > 0 ? Math.max(bits / seconds, 0) : 0;
  }
}
