<template>
  <section class="statistics-data section" :class="theme">
    <h5 v-html="getTranslation('diagnostics_connection_to_servers')" />

    <LoaderSimple v-if="isLoading" />

    <div v-if="!isLoading && failed" class="flex-center-vertical">
      <IconSVG :svg="IconCrossCircled" class="color-error" />&nbsp;<span
        v-html="getTranslation('diagnostics_no_data')"
      />
    </div>

    <div v-if="!isLoading && !failed" class="table-wrap">
      <table>
        <thead>
          <tr>
            <th v-html="getTranslation('diagnostics_resource')" />
            <th>Max</th>
            <th>Avg</th>
            <th>Min</th>
            <th v-html="getTranslation('diagnostics_tries')" />
            <th v-html="getTranslation('diagnostics_successful')" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in data" v-bind:key="row.resource">
            <td v-text="row.resource" />
            <td>{{ row.requestTime.max }}ms</td>
            <td>{{ row.requestTime.avg }}ms</td>
            <td>{{ row.requestTime.min }}ms</td>
            <td v-text="row.attempts" />
            <td v-text="row.successfulAttempts" />
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LoaderSimple from '../LoaderSimple.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCrossCircled from 'src/svg/cross-circled.svg';
import { TStat } from 'src/components/diagnostics/types';
import { SequoiaComponent } from 'src/mixins';

@Component({
  components: { LoaderSimple, IconSVG },
})
export default class Statistics extends SequoiaComponent {
  IconCrossCircled = IconCrossCircled;

  @Prop()
  isLoading!: boolean;

  @Prop({ required: true })
  data!: TStat[];

  get failed() {
    const successConnect = this.data.find(
      (row: { successfulAttempts: number }) => row.successfulAttempts > 0
    );
    return !successConnect || this.data.length === 0;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

$cell-padding: 0 16px;

.statistics-data {
  .table-wrap {
    overflow: auto;
  }

  table {
    width: 100%;
    line-height: 40px;
    @include body2;

    td,
    th {
      padding: 16px;
    }

    th {
      font-weight: normal;
      text-align: left;
      background-color: var(--c-light-150);
    }

    td {
      border-bottom-color: var(--c-light-150);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }
  }

  &.dark {
    th {
      background-color: var(--c-dark-200);
    }

    td {
      border-bottom-color: var(--c-dark-200);
    }
  }
}
</style>
