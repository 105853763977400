<template>
  <section class="section diagnostics-result" :class="theme">
    <h5 class="heading">
      <span v-html="getTranslation('diagnostics_result')" />
      <ButtonAction
        v-if="!(isLoading || internalError)"
        class="ml-8"
        size="large"
        :icon="IconRefresh"
        :text="getTranslation('diagnostics_restart_test')"
        @click="onRestartTests"
      />
    </h5>

    <div class="inner">
      <p v-if="!isLoading && errorsFormatted.length === 0 && !internalError" class="flex">
        <IconSVG :svg="IconCheckCircled" class="color-success" />&nbsp;<span
          v-html="getTranslation('diagnostics_problems_not_found')"
        />
      </p>

      <p v-if="internalError" class="flex">
        <IconSVG :svg="IconCrossCircled" class="color-error" />&nbsp;<span
          v-html="getTranslation('diagnostics_system_error_occurred_try_again_later')"
        />
      </p>

      <div v-if="!internalError && (errorsFormatted.length > 0 || isLoading)" class="errors">
        <div
          v-for="errorsGroup in errorsFormatted"
          :key="errorsGroup.speedTest.id"
          class="errors-group"
        >
          <div class="errors-group-title"><span v-html="errorsGroup.speedTest.title" />:</div>
          <div v-for="(error, index) in errorsGroup.errors" :key="error.title" class="error-block">
            <TextWithHint
              class="error-code"
              :class="getErrorColorClass(error)"
              :hint="error.description"
              :text="error.code || ''"
              border-style="dashed"
            />
            <span v-if="index < errorsGroup.errors.length - 1">, </span>
          </div>
        </div>

        <div v-if="isLoading" class="diagnostics-loader">
          <LoaderSimple />
        </div>
      </div>

      <p
        v-if="errorsFormatted.length > 0"
        class="color-tertiary"
        v-html="getTranslation('diagnostics_send_codes_of_received_errors_to_tech_support')"
      />
    </div>

    <DumpData
      v-if="errorsFormatted.length > 0"
      :errors="errorsFormatted"
      :user-data="userData"
      :smotreshka-server-name="smotreshkaServerName"
      :smotreshka-download="smotreshkaDownload"
      :smotreshka-upload="smotreshkaUpload"
      :internet-server-name="internetServerName"
      :internet-download="internetDownload"
      :internet-upload="internetUpload"
      :stat="stat"
    />
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LoaderSimple from './LoaderSimple.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconCrossCircled from 'src/svg/cross-circled.svg';
import IconRefresh from 'src/svg/refresh.svg';
import { TSpeedTestCheckEnhanced, TSpeedTestErrors } from 'src/api/app-info/types';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { SequoiaComponent } from 'src/mixins';
import DumpData from 'src/components/diagnostics/data/DumpData.vue';
import { TStat, TUserData } from 'src/components/diagnostics/types';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import TextWithHint from 'src/components/ui/TextWithHint.vue';

@Component({
  components: {
    TextWithHint,
    ButtonAction,
    ButtonDefault,
    IconSVG,
    LoaderSimple,
    DumpData,
    IconRefresh,
  },
})
export default class DiagnosticsResult extends SequoiaComponent {
  IconCheckCircled = IconCheckCircled;
  IconCrossCircled = IconCrossCircled;
  IconRefresh = IconRefresh;

  @Prop()
  isLoading!: boolean;

  @Prop()
  errors?: TSpeedTestCheckEnhanced[];

  @Prop()
  internalError?: boolean;

  @Prop({ required: true })
  userData!: TUserData[];

  @Prop({ required: true })
  stat!: TStat[];

  @Prop()
  smotreshkaServerName?: string;

  @Prop()
  smotreshkaDownload?: string | null;

  @Prop()
  smotreshkaUpload?: string | null;

  @Prop()
  internetServerName?: string | null;

  @Prop()
  internetDownload?: string | null;

  @Prop()
  internetUpload?: string | null;

  get errorsFormatted(): TSpeedTestErrors[] {
    const errorsBySpeedTest = this.errors?.reduce<Record<string, TSpeedTestErrors>>((acc, cur) => {
      acc[cur.speedTest.id] = acc[cur.speedTest.id] || {
        speedTest: cur.speedTest,
        errors: [],
      };
      acc[cur.speedTest.id].errors.push(cur);
      return acc;
    }, {});

    return errorsBySpeedTest ? Object.values(errorsBySpeedTest) : [];
  }

  getErrorColorClass(error: TSpeedTestCheckEnhanced) {
    return {
      'color-warning': error.severityLevel === 'warning',
      'color-error': error.severityLevel === 'error',
    };
  }

  onRestartTests() {
    this.$emit('restart-tests');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.diagnostics-result {
  .inner {
    min-height: 24px;
  }

  .heading {
    display: flex;
    align-items: center;
  }

  .icon {
    flex-shrink: 0;
  }

  .top {
    display: flex;
    align-items: center;
  }

  .diagnostics-loader {
    display: flex;
    align-items: center;
    min-height: 36px;
  }

  .errors-group {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    font-weight: bold;
  }

  .errors-group-title {
    @include body1;

    margin-right: 6px;
  }

  .error-block {
    margin-right: 6px;
    font-size: 24px;
    white-space: nowrap;
  }

  .error-code {
    white-space: normal;
  }
}
</style>
