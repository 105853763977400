import { render, staticRenderFns } from "./LoaderSimple.vue?vue&type=template&id=f3b6d4c8&scoped=true&"
import script from "./LoaderSimple.vue?vue&type=script&lang=ts&"
export * from "./LoaderSimple.vue?vue&type=script&lang=ts&"
import style0 from "./LoaderSimple.vue?vue&type=style&index=0&id=f3b6d4c8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3b6d4c8",
  null
  
)

export default component.exports