<template>
  <div class="loader-simple" :class="theme" />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class LoaderSimple extends Vue {
  @Prop()
  themeForced?: 'light' | 'dark';
  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
.loader-simple {
  position: relative;
  width: 6px;
  height: 6px;
  background-color: var(--c-overlay-lvl-40);
  border-radius: 50%;
  box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2), 24px 0 0 0 rgba(0, 0, 0, 1);
  animation: typing 1s linear infinite alternate;

  @keyframes typing {
    0% {
      background-color: rgba(0, 0, 0, 1);
      box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2), 24px 0 0 0 rgba(0, 0, 0, 0.2);
    }

    25% {
      background-color: var(--c-overlay-lvl-40);
      box-shadow: 12px 0 0 0 rgba(0, 0, 0, 2), 24px 0 0 0 rgba(0, 0, 0, 0.2);
    }

    75% {
      background-color: var(--c-overlay-lvl-40);
      box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2), 24px 0 0 0 rgba(0, 0, 0, 1);
    }
  }

  &.dark {
    box-shadow: 12px 0 0 0 rgba(255, 255, 255, 0.2), 24px 0 0 0 rgba(255, 255, 255, 1);

    @keyframes typing {
      0% {
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 12px 0 0 0 rgba(255, 255, 255, 0.2), 24px 0 0 0 rgba(255, 255, 255, 0.2);
      }

      25% {
        background-color: rgba(255, 255, 255, 0.4);
        box-shadow: 12px 0 0 0 rgba(255, 255, 255, 2), 24px 0 0 0 rgba(255, 255, 255, 0.2);
      }

      75% {
        background-color: rgba(255, 255, 255, 0.4);
        box-shadow: 12px 0 0 0 rgba(255, 255, 255, 0.2), 24px 0 0 0 rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
