<template>
  <div class="dump-data">
    <div v-html="getTranslation('diagnostics_recieved_data')" />
    <div class="inner">
      <div class="inner-wrap">
        <ButtonDefault @click="copyToClipboard">
          <span v-html="getTranslation('diagnostics_copy_to_clipboard')" />
        </ButtonDefault>
      </div>
      <ButtonDefault
        view="secondary"
        :href="'data:application/octet-stream, ' + encodedDump"
        :download="filename"
        @click="downloadFile"
      >
        <span v-html="getTranslation('diagnostics_save_to_file')" />
      </ButtonDefault>
    </div>

    <div class="notifications-container">
      <NotificationSimple
        v-if="notificationText"
        :icon="isNotificationError ? 'cross-circled' : 'check-circled'"
        :text="notificationText"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import * as clipboard from 'clipboard-polyfill';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import { Prop } from 'vue-property-decorator';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { TSpeedTestErrors } from 'src/api/app-info/types';
import { TStat, TUserData } from 'src/components/diagnostics/types';
import { SequoiaComponent } from 'src/mixins';
import NotificationSimple from 'src/components/ui/notifications/NotificationSimple.vue';

@Component({
  components: { NotificationSimple, ButtonDefault },
})
export default class DumpData extends SequoiaComponent {
  isNotificationError = false;
  notificationText = '';
  filename = 'data.txt';

  @Prop({ required: true })
  errors!: TSpeedTestErrors[];

  @Prop({ required: true })
  userData!: TUserData[];

  @Prop({ required: true })
  stat!: TStat[];

  @Prop()
  smotreshkaServerName?: string;

  @Prop()
  smotreshkaDownload?: string | null;

  @Prop()
  smotreshkaUpload?: string | null;

  @Prop()
  internetServerName?: string | null;

  @Prop()
  internetDownload?: string | null;

  @Prop()
  internetUpload?: string | null;

  get dump() {
    let dump = this.getTranslation('diagnostics_result');
    dump += '\n';
    dump += this.errors
      .map((errorsGroup) => {
        const errorsCodes = errorsGroup.errors.map((error) => error.code).join(', ');
        return `${errorsGroup.speedTest.title}: ${errorsCodes}`;
      })
      .join('\n');
    dump += '\n';
    dump += '\n';
    dump += this.getTranslation('diagnostics_user_data');
    dump += '\n';
    dump += this.userData.map(({ term, value }) => `${term}: ${value}`).join('\n');
    dump += '\n';
    dump += '\n';
    dump += `${this.getTranslation('diagnostics_smotreshka')} ${this.smotreshkaServerName}`;
    dump += '\n';
    dump += `${this.getTranslation('diagnostics_download')}: `;
    dump += this.smotreshkaDownload ? `${this.smotreshkaDownload} Mbit/s` : '-';
    dump += '\n';
    dump += '\n';
    dump += this.getTranslation('diagnostics_connection_to_servers');
    dump += '\n';
    dump += this.stat
      .map((row) =>
        [
          `${this.getTranslation('diagnostics_resource')}: ${row.resource}`,
          `Max: ${row.requestTime.max}ms`,
          `Avg: ${row.requestTime.avg}ms`,
          `Min: ${row.requestTime.min}ms`,
          `${this.getTranslation('diagnostics_tries')}: ${row.attempts}`,
          `${this.getTranslation('diagnostics_successful')}: ${row.successfulAttempts}`,
        ].join(' | ')
      )
      .join('\n');

    return dump;
  }

  get blob() {
    return new Blob([this.dump], { type: 'application/octet-stream' });
  }

  get encodedDump() {
    return encodeURI(this.dump);
  }

  copyToClipboard() {
    clipboard
      .writeText(this.dump)
      .then(() => {
        this.notificationText = this.getTranslation('diagnostics_copied');
        this.isNotificationError = false;
        this.clearNotificationText();
      })
      .catch(() => {
        this.notificationText = this.getTranslation('diagnostics_error');
        this.isNotificationError = true;
        this.clearNotificationText();
      });
  }

  clearNotificationText() {
    setTimeout(() => {
      this.notificationText = '';
      this.isNotificationError = false;
    }, convertToMilliseconds(3, 'second'));
  }

  downloadFile(e: { preventDefault: () => void }) {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      e.preventDefault();
      window.navigator.msSaveBlob(this.blob, this.filename);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.dump-data {
  margin-top: 16px;

  .inner {
    display: flex;
    margin-top: 8px;
    @include mobile {
      flex-direction: column;
    }
  }

  .inner-wrap {
    position: relative;
    margin-right: 16px;
    @include mobile {
      margin-right: 0;
    }

    .button {
      margin-right: 0;

      @include mobile {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
