<template>
  <CmsLayout class="diagnostics-page" data-cy="diagnostics-page" :class="theme">
    <main class="responsive-container">
      <BreadCrumbs
        class="pb-48 pb-tablet-32 pb-mobile-32"
        :with-current-level="false"
        :crumbs="crumbs"
      />

      <h2 v-html="getTranslation('diagnostics_title')" />

      <div class="inner">
        <div class="left">
          <DiagnosticsResult
            :errors="errors"
            :internal-error="internalError"
            :is-loading="!testsCompleted"
            :user-data="userData"
            :smotreshka-server-name="smotreshkaServerName"
            :smotreshka-download="smotreshkaDownloadSpeed"
            :smotreshka-upload="smotreshkaUploadSpeed"
            :internet-server-name="internetServerName"
            :internet-download="internetDownloadSpeed"
            :internet-upload="internetUploadSpeed"
            :stat="stat"
            @restart-tests="runTests"
          />

          <UserData :data="userData" :is-loading="userDataLoading" />

          <SpeedData
            :smotreshka-server-name="smotreshkaServerName"
            :smotreshka-download="smotreshkaDownloadSpeed"
            :smotreshka-download-color="smotreshkaDownloadColor"
            :smotreshka-upload="smotreshkaUploadSpeed"
            :internet-server-name="internetServerName"
            :internet-download="internetDownloadSpeed"
            :internet-download-color="internetDownloadColor"
            :internet-upload="internetUploadSpeed"
            :is-loading="!testsCompleted"
          />

          <StatisticsData :data="stat" :is-loading="!testsCompleted" />
        </div>

        <div class="right">
          <div v-if="!errors.length" class="ask-block">
            <span
              v-html="getTranslation('diagnostics_in_case_of_questions_contact_us_at')"
            />&#32;<a href="http://ask.smotreshka.tv" class="link nav-colored" target="_blank">
              ask.smotreshka.tv
            </a>
          </div>

          <div v-if="errors.length" class="ask-block">
            <span
              v-html="
                getTranslation(
                  'diagnostics_error_found_while_tests_running_you_can_create_ticket_at'
                )
              "
            />&#32;<a href="http://ask.smotreshka.tv" class="link nav-colored" target="_blank"
              >ask.smotreshka.tv</a
            >
          </div>
        </div>
      </div>
    </main>
  </CmsLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Bowser from 'bowser';
import { bitsPerSToMbitPerS, formatToFixed2 } from 'src/utils/number';
import { hasAdBlock } from 'src/utils';
import DiagnosticsResult from 'src/components/diagnostics/DiagnosticsResult.vue';
import UserData from 'src/components/diagnostics/data/UserData.vue';
import SpeedData from 'src/components/diagnostics/data/SpeedData.vue';
import StatisticsData from 'src/components/diagnostics/data/StatisticsData.vue';
import { createLinkTag } from 'src/utils/url';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { URLS } from 'src/constants';
import { getSpeedTests } from 'src/api/app-info';
import { TSpeedTestCheck, TSpeedTest, TSpeedTestCheckEnhanced } from 'src/api/app-info/types';
import TestCore from 'src/utils/diagnostics-speedtest/test-core';
import { TStat, TUserData } from 'src/components/diagnostics/types';
import { selectors } from 'src/store/selectors';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import { SequoiaPage } from 'src/mixins';
import BreadCrumbs from 'src/components/ui/BreadCrumbs.vue';
import { actions } from 'src/store/actions';
// import logger from 'src/utils/logger';

// const log = logger('diagnostics-page');

@Component({
  components: {
    ButtonDefault,
    IconSVG,
    StatisticsData,
    SpeedData,
    UserData,
    DiagnosticsResult,
    CmsLayout,
    BreadCrumbs,
  },
})
export default class DiagnosticsPage extends SequoiaPage {
  IconCross = IconCross;

  crumbs = [{ name: this.getTranslation('menu_homescreen'), link: '/' }];

  errors: TSpeedTestCheckEnhanced[] = [];
  stat: TStat[] = [];
  speedTests: TSpeedTest[] | null = null;
  userIp = '-';
  userData: TUserData[] = [];
  internalError = false;
  testsCompleted = false;
  userDataLoading = true;
  smotreshkaServerName = '';
  smotreshkaDownloadSpeed: string | null = null;
  smotreshkaDownloadColor = 'green';
  smotreshkaUploadSpeed: string | null = null;
  internetServerName = '';
  internetDownloadSpeed: string | null = null;
  internetDownloadColor = 'green';
  internetUploadSpeed: string | null = null;

  get account() {
    return selectors.account.accountSelector(this.$store);
  }

  async mounted() {
    actions.common.showFooter(this.$store);
    await this.loadInitialData();
    await this.runTests();
  }

  async loadInitialData() {
    this.userDataLoading = true;
    this.internalError = false;

    const [userIp, speedTests] = await Promise.all([
      this.fetchIpAddress(),
      this.fetchSpeedTests().catch(() => {
        console.error('Could not load speed tests');
        this.internalError = true;
        return [];
      }),
    ]).finally(() => {
      this.userDataLoading = false;
    });

    this.userIp = userIp;
    this.speedTests = speedTests;

    this.prepareUserData();
  }

  fetchSpeedTests() {
    return getSpeedTests().then((response) =>
      response.speedTests?.map((speedTest) => ({
        ...speedTest,
        humanReadableResource: createLinkTag(speedTest.resource).toString(),
      }))
    );
  }

  fetchIpAddress() {
    return fetch(URLS.diagnostics.ip)
      .then((response) => response.text())
      .catch(() => 'Unable to detect IP address');
  }

  async runTests() {
    this.testsCompleted = false;
    this.stat = [];
    this.errors = [];
    try {
      await this.doRunTests();
    } catch (e) {
      console.error(e);
    } finally {
      this.testsCompleted = true;
    }
  }

  doRunTests() {
    return new Promise<void>((resolve) => {
      (this.speedTests || [])
        .reduce(
          (promise, speedTest) => promise.then(() => this.runTest(speedTest)),
          Promise.resolve()
        )
        .then(() => {
          resolve();
        });
    });
  }

  runTest(speedTest: TSpeedTest) {
    return new Promise<void>((resolve) => {
      let test: TestCore | undefined;
      switch (speedTest.type) {
        case 'xhr-get':
          test = new TestCore(speedTest);
          break;
        case 'xhr-speed':
          test = new TestCore(speedTest);
          break;
        default:
        // do nothing
      }
      if (test) {
        test.run().then(({ failedChecks, stat, group }) => {
          this.onTestComplete(speedTest, failedChecks, stat, group);
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  onTestComplete(
    speedTest: TSpeedTest,
    failedChecks: TSpeedTestCheck[],
    stat: TStat,
    group: string
  ) {
    const failedChecksMapped = failedChecks.reduce<
      Record<string, Record<number, TSpeedTestCheckEnhanced>>
    >((acc, cur) => {
      acc[cur.severityLevel] = acc[cur.severityLevel] || {};
      acc[cur.severityLevel][cur.priority] = { ...cur, speedTest };
      return acc;
    }, {});
    Object.values(failedChecksMapped).forEach((failedChecksBySeverity) => {
      this.errors.push(Object.values(failedChecksBySeverity)[0]);
    });
    if (speedTest.type === 'xhr-get') {
      this.stat.push(stat);
    } else if (speedTest.type === 'xhr-speed') {
      let color = 'green';
      if (failedChecks.length) {
        const error = failedChecks.find((check) => check.severityLevel === 'error');
        color = error ? 'red' : 'yellow';
      }
      if (group === 'streams') {
        const MBitsPerS = bitsPerSToMbitPerS(stat.chunkDownloadSpeed.min);
        this.smotreshkaServerName = speedTest.humanReadableResource;
        this.smotreshkaDownloadSpeed = formatToFixed2(MBitsPerS);
        this.smotreshkaDownloadColor = color;
      } else if (group === 'internet') {
        const MBitsPerS = bitsPerSToMbitPerS(stat.chunkDownloadSpeed.min);
        this.internetServerName = speedTest.humanReadableResource;
        this.internetDownloadSpeed = formatToFixed2(MBitsPerS);
        this.internetDownloadColor = color;
      }
    }
  }

  prepareUserData() {
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const browser = bowser.getBrowser();
    const os = bowser.getOS();
    const osVersion = os.versionName || os.version;

    if (this.account) {
      const { user, provider } = this.account;

      if (user.id) {
        this.addUserDataTerm('ID пользователя', user.id);
      }
      if (user.login) {
        this.addUserDataTerm('Логин', user.login);
      }
      if (provider && provider.provider && provider.provider.name) {
        this.addUserDataTerm('Оператор видео', provider.provider.name);
      }
    }
    if (this.userIp) {
      this.addUserDataTerm('IP адрес', this.userIp);
    }
    this.addUserDataTerm('Браузер', `${browser.name} ${browser.version}`);
    this.addUserDataTerm('Операционная система', `${os.name} ${osVersion}`);
    this.addUserDataTerm('Блокировщик рекламы', hasAdBlock() ? 'Обнаружен' : 'Не обнаружен');
  }

  addUserDataTerm(term: string, value: string) {
    this.userData.push({ term, value });
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');
@import 'src/styles/placeholders-and-mixins/media-queries';

.diagnostics-page {
  padding-top: 48px;

  @include mobile-and-tablet {
    padding-top: 0;
  }

  > .responsive-container {
    padding-bottom: 48px;
  }

  table {
    border-collapse: collapse;
  }

  &::v-deep .section {
    padding: 24px 32px;
    margin-bottom: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    @include mobile-and-tablet {
      padding: 16px;
      margin-bottom: 16px;
    }
  }

  .inner {
    display: flex;

    @include mobile-and-desktop-s {
      flex-direction: column-reverse;
    }
  }

  .left {
    position: relative;
    width: 100%;
    max-width: 960px;
  }

  .right {
    padding-left: 48px;

    @include mobile-and-desktop-s {
      padding-left: 0;
    }
  }

  .ask-block {
    max-width: 292px;
    padding: 8px 0 8px 16px;
    word-break: break-word;
    border-left: 4px solid var(--c-light-brand-lighter);

    @include mobile-and-desktop-m {
      max-width: 792px;
      margin-top: 0;
      margin-bottom: 32px;
    }

    @include tablet {
      padding: 8px 0 8px 8px;
    }

    @include mobile {
      padding: 4px 0 4px 8px;
    }

    .ask-block-button {
      padding: 20px 0 10px;
    }
  }

  // TODO Do we need this?
  .feedback-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    opacity: 0;
    transition: opacity 0.3s ease;
    transform: translateX(-100%);

    &.visible {
      opacity: 1;
      transform: none;
    }

    .button-close {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: 0;
    }

    iframe {
      min-height: 100vh;
    }
  }

  &.dark {
    .section {
      background-color: var(--c-dark-150);

      &::v-deep .section {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}
</style>
