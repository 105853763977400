import * as arrayUtils from 'src/utils/array';
import { bitsPerSToMbitPerS, formatToFixed2 } from 'src/utils/number';
import { TSpeedTestCheck } from 'src/api/app-info/types';
import { selectors } from 'src/store/selectors';
import { store } from 'src/store';

export default class TestChecks {
  public testName: string;

  public resource: string;

  constructor(testName: string, resource: string) {
    this.testName = testName.toLowerCase();
    this.resource = resource;
  }

  public checkAverageMs(check: TSpeedTestCheck, requestTime: number[][]): TSpeedTestCheck | null {
    const avg = arrayUtils.avg(arrayUtils.flatten(requestTime)) || 0;
    const passed = avg <= check.threshold;
    const translationKey = 'diagnostics_check_average_ms'; // Среднее время теста «%testName% %resource%» = %result%ms, ожидалось < %threshold%ms
    return this.prepareResult(check, passed, avg, translationKey);
  }

  public checkMaxMs(check: TSpeedTestCheck, requestTime: number[][]): TSpeedTestCheck | null {
    const max = arrayUtils.max(arrayUtils.flatten(requestTime)) || 0;
    const passed = max <= check.threshold;
    const translationKey = 'diagnostics_check_max_ms'; // Максимальное время теста «%testName% %resource%» = %result%ms, ожидалось < %threshold%ms
    return this.prepareResult(check, passed, max, translationKey);
  }

  public checkMinMs(check: TSpeedTestCheck, requestTime: number[][]) {
    const min = arrayUtils.min(arrayUtils.flatten(requestTime)) || 0;
    const passed = min <= check.threshold;
    const translationKey = 'diagnostics_check_min_ms'; // Минимальное время теста «%testName% %resource%» = %result%ms, ожидалось > %threshold%ms
    return this.prepareResult(check, passed, min, translationKey);
  }

  public checkSuccessfulAttempts(check: TSpeedTestCheck, successfulAttempts: number) {
    const passed = successfulAttempts >= check.threshold;
    const translationKey = 'diagnostics_check_successful_attempts'; // Количество успешных попыток теста "%testName% %this.resource%" = %result%, ожидалось > %threshold%
    return this.prepareResult(check, passed, successfulAttempts, translationKey);
  }

  public checkFailedAttempts(check: TSpeedTestCheck, failedAttempts: number) {
    const passed = failedAttempts <= check.threshold;
    const translationKey = 'diagnostics_check_failed_attempts'; // Количество неудачных попыток теста «%testName% %resource% = %result%, ожидалось < %threshold%
    return this.prepareResult(check, passed, failedAttempts, translationKey);
  }

  public checkMinChunkDownloadSpeed(check: TSpeedTestCheck, chunkDownloadSpeed: number[]) {
    const minChunkDownloadSpeed = arrayUtils.min(chunkDownloadSpeed) || 0;
    const passed = minChunkDownloadSpeed >= check.threshold;
    const translationKey = 'diagnostics_check_min_chunk_download_speed'; // Минимальная скорость загрузки чанка c %resource% = %result%Mbits/s, ожидалось >= %threshold%Mbits/s
    return this.prepareResult(check, passed, minChunkDownloadSpeed, translationKey, true);
  }

  public checkAvgChunkDownloadSpeed(check: TSpeedTestCheck, chunkDownloadSpeed: number[]) {
    const avgChunkDownloadSpeed = arrayUtils.avg(chunkDownloadSpeed) || 0;
    const passed = avgChunkDownloadSpeed >= check.threshold;
    const translationKey = 'diagnostics_check_avg_chunk_download_speed'; // Средняя скорость загрузки чанка c %resource% = %result%Mbits/s, ожидалось >= %threshold%Mbits/s
    return this.prepareResult(check, passed, avgChunkDownloadSpeed, translationKey, true);
  }

  public checkMinFileDownloadSpeed(check: TSpeedTestCheck, fileDownloadSpeed: number[]) {
    const minFileDownloadSpeed = arrayUtils.min(fileDownloadSpeed) || 0;
    const passed = minFileDownloadSpeed >= check.threshold;
    const translationKey = 'diagnostics_check_min_file_download_speed'; // Средняя скорость загрузки файла c %resource% = %result%Mbits/s, ожидалось >= %threshold%Mbits/s
    return this.prepareResult(check, passed, minFileDownloadSpeed, translationKey, true);
  }

  public checkAvgFileDownloadSpeed(check: TSpeedTestCheck, fileDownloadSpeed: number[]) {
    const avgFileDownloadSpeed = arrayUtils.min(fileDownloadSpeed) || 0;
    const passed = avgFileDownloadSpeed >= check.threshold;
    const translationKey = 'diagnostics_check_avg_file_download_speed'; // Средняя скорость загрузки файла c %testName% %resource% = %result%Mbits/s, ожидалось >= %threshold%Mbits/s
    return this.prepareResult(check, passed, avgFileDownloadSpeed, translationKey, true);
  }

  public prepareResult(
    check: TSpeedTestCheck,
    passed: boolean,
    value: number,
    translationKey: string,
    isSpeedTest = false
  ) {
    if (passed) {
      return null;
    }

    let description = '';
    if (check.threshold) {
      let threshold;
      let valueFormatted;
      if (isSpeedTest) {
        threshold = bitsPerSToMbitPerS(check.threshold);
        threshold = formatToFixed2(threshold);
        valueFormatted = bitsPerSToMbitPerS(value);
        valueFormatted = formatToFixed2(valueFormatted);
      } else {
        threshold = Math.round(check.threshold).toString();
        valueFormatted = Math.round(value).toString();
      }

      description = selectors.translations
        .translationSelector(store, translationKey)
        .replace('%testName%', this.testName)
        .replace('%resource%', this.resource)
        .replace('%result%', valueFormatted)
        .replace('%threshold%', threshold);
    }

    return {
      code: check.title,
      description,
      result: value,
      ...check,
    };
  }
}
