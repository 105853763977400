<template>
  <section class="user-data section" :class="theme">
    <h5 class="pb-20" v-html="getTranslation('diagnostics_user_data')" />
    <LoaderSimple v-if="isLoading" />
    <div v-if="!isLoading" class="user-data-list">
      <div v-for="item in data" :key="item.term" class="user-data-row">
        <div class="term color-tertiary" v-text="item.term" />
        <div class="value" v-text="item.value" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LoaderSimple from '../LoaderSimple.vue';
import { SequoiaComponent } from 'src/mixins';

@Component({
  components: {
    LoaderSimple,
  },
})
export default class UserData extends SequoiaComponent {
  @Prop()
  isLoading!: boolean;

  @Prop()
  data?: Array<{ term: string; value: string }>;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.user-data {
  min-height: 349px;

  .user-data-list {
    width: 100%;
  }

  .user-data-row {
    display: flex;
    padding-bottom: 24px;

    @include mobile-and-tablet {
      flex-direction: column;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .term {
    width: 50%;
    padding-right: 32px;
    white-space: nowrap;

    @include mobile-and-tablet {
      width: 100%;
      padding-right: 0;
      padding-bottom: 0;
      white-space: normal;
    }
  }

  .value {
    word-break: break-all;

    @include mobile-and-tablet-s {
      width: 55%;
    }
  }
}
</style>
